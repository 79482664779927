<template>
    <div class="c_login">
        <!-- 普通登录 -->
        <div v-show="scancode==false">
            <div class="wxBg" @click="wxBox()"></div>
            <div class="account_nav">
                <a :class="num==1?'on':''" @click="onNum(1)">密码登录</a>
                <a :class="num==2?'on':''" @click="onNum(2)">短信登录</a>
            </div>
            <!-- 密码登录 -->
            <ul class="account_ul" v-show="num==1">
                <li class="prompt">
                    <div class="prompt_div">
                        <span>请输入密码</span>
                    </div>
                </li>
                <li class="bag_aside_item clearfix">
                    <input type="text" class="item_input" placeholder="请输入手机号">
                </li>
                <li class="bag_aside_item clearfix">
                    <input type="password" class="item_input" placeholder="请输入密码">
                </li>
                <li class="bag_aside_item clearfix">
                    <div class="fl">
                        <el-checkbox v-model="checked">两周内自动登录</el-checkbox>
                    </div>
                    <div class="fr account_f">
                        <router-link :to="{name:'forget'}">忘记密码</router-link>
                    </div>
                </li>
                <li class="bag_aside_item clearfix">
                    <button class="item_but">登录</button>
                </li>
                <li class="bag_aside_item clearfix mt30" style="text-align: center;">
                    <span class="font14">使用第三方登录 或 <router-link :to="{name:'reg'}" class="regHref" target="_self">免费注册</router-link></span>
                </li>
                <li class="bag_aside_item clearfix mt15">
                    <div class="third_div fl">
                        <a class="cycle1 wxType" @click="wxBox()">
                            <span>微信</span>
                        </a>
                    </div>
                    <div class="third_div fl">
                        <a class="cycle1 qqType">
                            <span>QQ</span>
                        </a>
                    </div>
                </li>
            </ul>
            <!-- 验证码登录 -->
            <ul class="account_ul" v-show="num==2">
                <li class="prompt">
                    <div class="prompt_div">
                        <span>请输入密码</span>
                    </div>
                </li>
                <li class="bag_aside_item clearfix">
                    <input type="text" class="item_input" placeholder="请输入手机号">
                </li>
                <li class="bag_aside_item clearfix">
                    <div class="item_div_l fl">
                        <input type="text" class="item_input" placeholder="请输入验证码">
                    </div>
                    <div class="item_div_r fr">
                        <el-button type="primary" plain>发送验证码</el-button>
                    </div>
                </li>
                <li class="prompt">
                </li>
                <li class="bag_aside_item clearfix">
                    <button class="item_but">登录</button>
                </li>
                <li class="bag_aside_item clearfix mt30" style="text-align: center;">
                    <span class="font14">使用第三方登录 或 <router-link :to="{name:'reg'}" class="regHref" target="_self">免费注册</router-link></span>
                </li>
                <li class="bag_aside_item clearfix mt15">
                    <div class="third_div fl">
                        <a class="cycle1 wxType">
                            <span>微信</span>
                        </a>
                    </div>
                    <div class="third_div fl">
                        <a class="cycle1 qqType">
                            <span>QQ</span>
                        </a>
                    </div>
                </li>
            </ul>
        </div>
        <!-- 微信扫码登录 -->
        <div v-show="scancode==true">
            <div class="userBg" @click="userBox()"></div>
            <div class="wx_login">
                 <img src="../img/wx_icon.png" alt="">微信扫码登录
            </div>
            <div class="wx-QR-code">
                <iframe src="https://open.weixin.qq.com/connect/qrconnect?appid=wxf672178f6a0d84e8&scope=snsapi_login&redirect_uri=https%3A%2F%2Flogin.nipic.com%2Faccount%2Fauth%2FWechat%2FCallback&state=&login_type=jssdk&self_redirect=false&styletype=&sizetype=&bgcolor=&rst=&style=black&href=data:text/css;base64,LmxvZ2luUGFuZWwgLnRpdGxle2Rpc3BsYXk6IG5vbmV9DQojd3hfZGVmYXV
sdF90aXAgcHtkaXNwbGF5OiBpbmxpbmUtYmxvY2t9" frameborder="0" scrolling="no" width="300px" height="350px"></iframe>
            </div>
            <div class="reg_div">
                <router-link :to="{name:'reg'}" target="_self" class="regHref">免费注册用户</router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            num:1,
            checked:false,
            scancode:false,//是否扫码登录
        }
    },
    methods: {
        onNum(val){
            this.num=val
        },
        // 跳转账户登录
        userBox(){
            this.scancode = false
        },
        //跳转微信扫码登录
        wxBox(){
            this.scancode = true
        },
    },
    mounted() {
        
    },
}
</script>
<style scoped>
@import '../css/login1.css';
</style>
<style>
.item_div_r .el-button{
    padding: 12px 15px;
}
</style>